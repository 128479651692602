@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-SemiBold.ttf");
  font-weight: 700;
  font-display: swap;
}
